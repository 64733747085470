import React from 'react';
import styles from './styles.module.scss';

export default function Footer() {
    return (
        <footer className={styles.footer}>
            ® Todos os direitos reservados
        </footer>
    );
}
