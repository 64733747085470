import React from "react";
import styles from "./styles.module.scss";
import MinhasContas from "./MinhasContas";
import ContasPagar from "./ContasPagar";
import CartaoCredito from "./CartaoCredito";
import ContasReceber from "./ContasReceber";
import BalancoMes from "./BalancoMes";
import GastosMes from "./GastosMes";

export default function InfoGeraisDashboard() {
    return (
        <>
            <div className={styles.dashboards}>
                <div>
                    <MinhasContas />
                    <ContasReceber />
                    <ContasPagar />
                </div>

                <div>
                    <BalancoMes />
                    <CartaoCredito />
                    <GastosMes />
                </div>
            </div>
        </>
    );
}
