import React from 'react'
import styles from './styles.module.scss'

export default function Modal({exibirModal, children}) {
    return (
        <div className={styles.modal} style={{
            display:`${exibirModal ? 'flex': 'none'}`
        }}>
            <div className={styles.modal_container}>
                {children}
            </div>
        </div>
    );
}
