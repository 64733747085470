import React, { useState } from "react";
import CustomRangeDate from "../../components/CustomRangeDate";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import FinancasAPI from '../../services/FinancasAPI';
import CardLancamento from "../../components/CardLancamento";
import ModalReceitas from "../../components/Modal/ModalReceita";
import CardLancamentoLoading from "../../components/CardLancamentoLoading";


export default function Lancamentos() {
    useDocumentTitle("Lançamentos");

    const { data, isLoading } = useQuery("bancos", FinancasAPI.lancamento.all);
    const { register, handleSubmit, watch, setValue } = useForm();
    const [exibirModalReceita, setExibirModalReceita] = useState(false);

    const changeStartDate = (value) => {
        setValue("start_date", value);
    }

    const changeEndDate = (value) => {
        setValue("end_date", value);
    }

    const lancamentos = () => {
        if (isLoading) {
            return <CardLancamentoLoading cards={10}/>
        }

        if (data?.data.length === 0) {
            return (
                <>
                <h1>
                    Inserir Despesas ou Receitas para funcionar o
                    Demonstrativo de Movimetação
                </h1>

                    <button
                        className="btn btn_verde"
                        onClick={() => setExibirModalReceita(true)}
                    >
                        Adicionar Novo Receitas
                    </button>
                    </>
            )
        }

        return (
           <CardLancamento lancamentos={data.data}/>
        )
    }

    return (
        <>
        <div className="bg-white flex flex-col p-5 gap-5">
            <div className="flex justify-between">
                <h2>Lançamentos</h2>

                <CustomRangeDate
                    changeStartDate={changeStartDate}
                    changeEndDate={changeEndDate}
                />

                <div className="flex gap-3">
                    <a href="Contas"><h4>Contas</h4></a>
                    <a href="Cartoes"><h4>Cartões</h4></a>
                </div>
            </div>

            <div className="bg-gray flex justify-between px-3 py-2">
                <div><h3>Filtro</h3></div>
                <div><h3>Seach</h3></div>
            </div>

            <div>
                {lancamentos()}
            </div>
        </div>

        <ModalReceitas dataModal={[]}
                exibirModal={exibirModalReceita}
                setExibirModal={setExibirModalReceita}
            />

        </>
    );
}
