import { Skeleton } from "@mui/material";

export default function CardLancamentoLoading(props) {
    const cards = props.cards ?? 3;

    const skeletons = () => {
        const rows = [];

        for (let index = 0; index < cards; index++) {
            rows.push(<Skeleton animation="wave" variant="rectangular" height={60} />)
        }

        return rows;
    }

    return (
        <div className="flex flex-col gap-3">
            {skeletons()}

            <div className="flex justify-end">
                <Skeleton animation="wave" variant="rectangular" width={150} height={20} />
            </div>
        </div>
    )
}