import React, { useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import Card from "../Card";
import { UserContext } from '../../../App';

export default function MinhasContas() {
    const user = useContext(UserContext)

    return (
        <>
            <Card title="Minhas Contas">
                <div>
                    <input type="radio" />
                    Esconder saldo
                </div>

                <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                        <FontAwesomeIcon icon={faWallet} />
                        Contas
                    </div>

                    <div className={`font-bold ${user.wallet > 0 ? "text-color-success" : "text-color-danger"}`}>R$ {user.wallet || 0}</div>
                </div>
            </Card>
        </>
    );
}
