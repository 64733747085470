const convertDateToLocaleDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    return date.toLocaleDateString(undefined, options);
}

export {
    convertDateToLocaleDate
}
