import { useQuery } from "react-query";
import { useNavigate, Outlet } from "react-router-dom";
import FinancasAPI from "../services/FinancasAPI";

export default function AuthMiddleware({ setUser }) {
    const navigate = useNavigate();

    const userToken = localStorage.getItem("userToken");

    const { data, isLoading, error } = useQuery("getMe", () => {
        if (!userToken) {
            return navigate("/login");
        }

        const user = FinancasAPI.user.getMe();

        user.then((data) => {
            setUser(data.data);
        });

        return user;
    });

    if (isLoading) {
        return (
            <div>Carregando...</div>
        );
    }

    if (error) {
        return navigate("/login");
    }

    return (
        <Outlet />
    );
}
