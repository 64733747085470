import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import FinancasAPI from "../../../services/FinancasAPI";
import CardLancamento from "../../CardLancamento";
import CardLancamentoLoading from "../../CardLancamentoLoading";
import ModalReceitas from "../../Modal/ModalReceita";
import Card from "../Card";

export default function ContasReceber() {
    const [exibirModalReceita, setExibirModalReceita] = useState(false);

    const { data, isLoading } = useQuery("futureRecipe", FinancasAPI.lancamento.futureRecipe);

    const content = () => {
        if (isLoading) {
            return <CardLancamentoLoading/>
        }

        if (data.data.length === 0) {
            return (
                <div className="flex flex-col items-center gap-3">
                    <h3>Sem contas a receber pendentes</h3>
                    <p>Você não possui contas pendentes a receber</p>
                    <button
                        className="btn btn-success"
                        onClick={() => setExibirModalReceita(true)}
                    >
                        Adicionar Novo Receitas
                    </button>
                </div>
            )
        }

        return (
            <>
                <CardLancamento lancamentos={data.data}/>

                <div className="flex justify-end font-bold">
                    <Link to="/lancamentos">Ir para lançamentos</Link>
                </div>
            </>
        )
    }

    return (
        <>
            <Card title="Contas a Receber">
                {content()}
            </Card>

            <ModalReceitas dataModal={[]}
                exibirModal={exibirModalReceita}
                setExibirModal={setExibirModalReceita}
            />
        </>
    );
}
