import React from "react";
import Modal from "../Modal";
import styles from "./styles.module.scss";
import { TextField } from "@mui/material";

export default function ModalCategoria({ exibirModal, setExibirModal }) {
    return (
        <Modal exibirModal={exibirModal}>
            <h2>Cadastrar nova categoria</h2>

            <div className={styles.modal_corpo}>
                <form>
                    <div>
                        <TextField
                            className="w-100"
                            label="Nome da Categoria"
                            variant="outlined"
                        />
                    </div>
                </form>

                <div>
                    <button
                        className="btn btn-gray btn-bold"
                        onClick={() => setExibirModal(false)}
                    >
                        Cancelar
                    </button>

                    <button
                        className="btn btn-success btn-bold"
                        onClick={() => setExibirModal(false)}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </Modal>
    );
}