import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import FinancasAPI from '../../services/FinancasAPI';

export default function Bancos({form}) {
    const { data, isLoading } = useQuery("bancos", FinancasAPI.banco.all);

    const {register, watch} = form;

    const value = Number.parseInt(watch("bank_id"));

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>Banco</InputLabel>

                <Select {...register("bank_id")} value={value} required>
                    {!isLoading ? data.data.map(bank => {
                        return (
                            <MenuItem key={bank.id} value={String}>{bank.name}</MenuItem>
                        )
                    }) : null}
                </Select>
            </FormControl>
        </div>
    );
}
