import React from "react";
import { Link } from "react-router-dom";
import Card from "../Card";

export default function CartaoCredito() {
    return (
        <>
            <Card title="Cartões de Crédito">
                <div className="flex flex-col items-center gap-3">
                    <h3>Sem catão cadastrado</h3>
                    <p>
                        Cadastre seus cartões de créditos para acompanhar aqui
                    </p>

                    <Link to="/CartaoConta">
                        <button className="btn btn-success">
                            Adicionar Cartão
                        </button>
                    </Link>
                </div>
            </Card>
        </>
    );
}