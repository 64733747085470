import { useContext, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { UserContext } from '../../../App';

export default function HeaderAvatar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);;
    const handleClose = () => setAnchorEl(null);

    const user = useContext(UserContext)

    return (
        <>
            <Avatar alt={user.name} src={user.avatar} className="cursor-pointer" onClick={handleClick} />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Link to="/logout"><MenuItem onClick={handleClose}>Sair</MenuItem></Link>
            </Menu>
        </>
    );
}