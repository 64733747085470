import { TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import FinancasAPI from "../services/FinancasAPI";
import styles from "./login.module.scss";

export default function Login() {
    useDocumentTitle("Login");

    const navigate = useNavigate();

    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        FinancasAPI.user.login(data).then((data) => {
            localStorage.setItem("userToken", data.data.d.authorization);
            navigate("/");
        }).catch(() => {
            alert("Aconteceu erro na hora do login");
        });
    };

    return (
        <div className={styles.dashboard_login}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.logo}>
                    <img src="/assets/img/logo.png" />
                </div>

                <div className={styles.login}>
                    <div>
                        <TextField
                            label="E-mail"
                            variant="outlined"
                            {...register("email")}
                            required
                        />
                    </div>

                    <div>
                        <TextField
                            type="password"
                            label="Digite sua Senha"
                            variant="outlined"
                            {...register("password")}
                            required
                        />
                    </div>

                    <div>
                        <button type="submit" class="btn btn-success">
                            Entrar
                        </button>
                    </div>
                </div>

                <div className={styles.set_login}>
                    <div>
                        <Link to="/register">Criar Conta</Link>
                    </div>

                    <div>
                        <Link to="/recovery">Rucuperar senhar</Link>
                    </div>
                </div>
            </form>
        </div>
    );
}
