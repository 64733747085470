import React, { useEffect } from "react";
import FinancasAPI from "../../../services/FinancasAPI";
import { useForm } from "react-hook-form";
import Bancos from "../../Bancos";
import CunstonLimiteDate from "../../CustomLimiteDate";
import Modal from "../Modal";
import { TextField } from "@mui/material";

export default function ModalCartao({
    dataModal,
    exibirModal,
    setExibirModal,
}) {
    const form = useForm();
    const { register, handleSubmit, reset } = form;

    useEffect(() => {
        reset({
            name: "",
            limit: "",
            bank_id: "",
            expiring_month: "",
            expiring_year: ""
        })

        if (Object.keys(dataModal).length > 0) {
            reset(dataModal)
        }
    }, [dataModal])

    const salvar = (data) => {
        const result = dataModal.id ?
            FinancasAPI.cartao.update(dataModal.id, data) :
            FinancasAPI.cartao.create(data);

        result.then(() => {
            setExibirModal(false);
            window.location.reload(false);
        });
    };

    return (
        <Modal exibirModal={exibirModal}>
            <h3>Novo Cartão</h3>

            <div className="flex flex-col gap-5">
                <div className="w-100">
                    <h4>Nome do Cartão</h4>

                    <TextField
                        label="Nome do Cartão"
                        variant="outlined"
                        {...register("name")}
                    />
                </div>

                <div>
                    <h4>Limite</h4>

                    <TextField
                        label="Nome Limite Cartão"
                        variant="outlined"
                        {...register("limit")}
                    />
                </div>

                <div>
                    <h4>Banco</h4>

                    <Bancos form={form} />
                </div>

                <div>
                    <h4>Validade do Cartão</h4>

                    <CunstonLimiteDate propsExpiringMonth={register("expiring_month")} propsExpiringYear={register("expiring_year")} />
                </div>

                <div className="flex justify-center gap-5">
                    <button
                        className="btn btn-gray"
                        onClick={() => setExibirModal(false)}
                    >
                        Cancelar
                    </button>

                    <button
                        className="btn btn-success"
                        onClick={handleSubmit(salvar)}
                    >
                        Cadastrar
                    </button>
                </div>
            </div>
        </Modal>
    );
}
