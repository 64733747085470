import React, { useState } from 'react';
import styles from "./styles.module.scss";
import ModalCartao from '../Modal/ModalCartao';
import FinancasAPI from '../../services/FinancasAPI';
import { useQuery } from 'react-query';

export default function CartaoContas() {
    const [dataModal, setDataModal] = useState([]);
    const [exibirModal, setExibirModal] = useState(false)
    const { data, isLoading, error } = useQuery("cartoes", FinancasAPI.cartao.all);

    const deleteCard = async (cardId) => {
       await FinancasAPI.cartao.delete(cardId)
        window.location.reload(false);
    }

    const openModal = (data) => {
        setDataModal(data);
        setExibirModal(true);
    };

    return (
        <>
            <div className={styles.dashboard_cartao}>
                <div className={styles.titulo}>
                    <h3>Cartões de Crédito</h3>
                </div>

                <div className={styles.dashboard_card}>
                    {!isLoading ? data.data.d.map(card => {
                        return (
                            <div key={card.id} className={styles.card_cartao}>
                                <div className={styles.editar_card}></div>
                                <div className={styles.card_nome}>{card.name}</div>
                                <div>
                                    <div className={styles.editar_card_val}>Validade: {card.expiring_month} | {card.expiring_year}</div>
                                </div>


                                <div className={styles.edicao_cartao}>
                                    <button className="btn btn-success" onClick={() => openModal(card)}>Editar Cartão</button>
                                </div>

                                <div className={styles.edicao_cartao}>
                                    <button className="btn btn-gray" onClick={() => deleteCard(card.id)}>Excluir</button>
                                </div>
                            </div>
                        )
                    }) : null}

                    <div className={styles.card_cartao}>
                        <h4>Adiconar Cartão</h4>

                        <div className={styles.edicao_cartao}>
                            <button className="btn btn-success" onClick={() => openModal([])}>Adicionar Cartão</button>
                        </div>
                    </div>

                </div>
            </div>

            <ModalCartao dataModal={dataModal} exibirModal={exibirModal} setExibirModal={setExibirModal} />
        </>
    );
}
