import React from "react";
import FinancasAPI from "../../../services/FinancasAPI";
import { useQuery } from "react-query";
import Card from "../Card";

export default function BalancoMes() {
    const { data } = useQuery("balanceOfMonth", FinancasAPI.user.getBalanceOfMonth);

    return (
        <Card title="Balanço do Mês">
            <span className="bg-success">R$ {data?.data.d.recipe || 0}</span>
            <span className="bg-danger">R$ {data?.data.d.expense || 0}</span>
        </Card>
    );
}
