import FinancasAPI from "../FinancasAPI";

export default {
    async getMe() {
        return await FinancasAPI.api.get("/user");
    },

    async getBalanceOfMonth()
    {
        return await FinancasAPI.api.get("/balance");
    },

    async login({email, password}) {
        return await FinancasAPI.api.post("/login", {
            email,
            password
        });
    },

    async register({name, email, password, confirmPassword}) {
        return await FinancasAPI.api.post("/user/register",{
            name,
            email,
            password,
            confirmPassword
        });
     },
}
