import React, { createContext, useState } from 'react';
import './index.css';
import 'node-snackbar/src/sass/snackbar.sass';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages';
import Lancamentos from './pages/Lancamentos';
import Relatorio from './pages/Relatorios';
import LimitesDeGastos from './pages/LimitesDeGastos';
import LayoutDefault from './layouts/LayoutDefault';
import LayoutLogin from './layouts/LayoutLogin';
import Categoria from './pages/Categoria';
import Login from './pages/login';
import Logout from './pages/logout';
import AuthMiddleware from './middlewares/AuthMiddleware';
import CartaoConta from './components/CartaoConta';
import Register from './pages/Register';

export const UserContext = createContext()

export default function App() {
    const [user, setUser] = useState({});

    return (
        <UserContext.Provider value={user}>
            <BrowserRouter>
                <Routes>
                    <Route path="login" element={<LayoutLogin component={Login} />} />
                    <Route path="register" element={<Register />} />

                    <Route element={<AuthMiddleware setUser={setUser}/>}>
                        <Route path="/" element={<LayoutDefault component={Index} />} />
                        <Route path="logout" element={<Logout/>} />
                        <Route path="lancamentos" element={<LayoutDefault component={Lancamentos} />} />
                        <Route path="relatorio" element={<LayoutDefault component={Relatorio} />} />
                        <Route path="LimitesDeGastos" element={<LayoutDefault component={LimitesDeGastos} />} />
                        <Route path ="CartaoConta" element={<LayoutDefault component={CartaoConta} />} />
                        <Route path ="Categoria" element={<LayoutDefault component={Categoria} /> } />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    )
};
