import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';

export default function CustomRangeDate({changeStartDate, changeEndDate}) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const style = {
        maxWidth: 380
    }

    return (
        <div className="flex items-center gap-4" style={style}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label="Início"
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    onChange={(newValue) => {
                        changeStartDate(newValue);
                        setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>

            <h4>Até</h4>

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label="Término"
                    inputFormat="DD/MM/YYYY"
                    value={endDate}
                    onChange={(newValue) => {
                        changeEndDate(newValue);
                        setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
}