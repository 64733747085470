import React from 'react';
import styles from './styles.module.scss';

export default function ListaCategoria({categoria}) {
    return (
        <div className={styles.list}>
            <b>{categoria.name}</b>
        </div>
    );
}
