import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function CunstonLimiteDate({propsExpiringMonth, propsExpiringYear}) {
    return (
        <div className="flex gap-3">
            <FormControl style={{width:100}}>
                <InputLabel>Mês</InputLabel>

                <Select label {...propsExpiringMonth} required>
                    <MenuItem value="">--</MenuItem>
                    <MenuItem value="1">01</MenuItem>
                    <MenuItem value="2">02</MenuItem>
                    <MenuItem value="3">03</MenuItem>
                    <MenuItem value="4">04</MenuItem>
                    <MenuItem value="5">05</MenuItem>
                    <MenuItem value="6">06</MenuItem>
                    <MenuItem value="7">07</MenuItem>
                    <MenuItem value="8">08</MenuItem>
                    <MenuItem value="9">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                </Select>
            </FormControl>

            <FormControl style={{width:100}}>
                <InputLabel>Ano</InputLabel>

                <Select label {...propsExpiringYear} required>
                    <MenuItem value="">--</MenuItem>
                    <MenuItem value="2023">23</MenuItem>
                    <MenuItem value="2024">24</MenuItem>
                    <MenuItem value="2025">25</MenuItem>
                    <MenuItem value="2026">26</MenuItem>
                    <MenuItem value="2027">27</MenuItem>
                    <MenuItem value="2028">28</MenuItem>
                    <MenuItem value="2029">29</MenuItem>
                    <MenuItem value="2030">30</MenuItem>
                    <MenuItem value="2031">31</MenuItem>
                    <MenuItem value="2032">32</MenuItem>
                    <MenuItem value="2033">33</MenuItem>
                    <MenuItem value="2034">34</MenuItem>
                    <MenuItem value="2035">35</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}
