import axios from "axios";
import Banco from "./Banco";
import Cartao from "./Cartao";
import Categoria from "./Categoria";
import Lancamento from "./Lancamento";
import User from "./User";

const baseURL = "https://financas-api-prd.snowdev.com.br";

const api = axios.create({ baseURL });

api.interceptors.request.use((config) => {
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
        config.headers.Authorization = `Bearer ${userToken}`;
    }

    return config;
});

const instance = {}

export default {
    instance,
    api,

    user: User,
    cartao: Cartao,

    banco: Banco,

    categoria: Categoria,
    lancamento: Lancamento,

}
