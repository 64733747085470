import React from "react";
import styles from "./styles.module.scss";

export default function Card(props) {
    const {title, children} = props;

    return (
        <>
            <div className={styles.card}>
                <h3 className={styles.titlo}>{title}</h3>

                <div className="flex flex-col gap-3">
                    {children}
                </div>
            </div>
        </>
    );
}
