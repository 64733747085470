import React, { useContext, useState } from 'react';
import styles from './styles.module.scss';
import { faPlus, faMinus, faArrowsLeftRight, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalReceitas from '../Modal/ModalReceita';
import ModalDespesas from '../Modal/ModalDespesas';
import ModalTransferencia from '../Modal/ModalTransferencia';
import { UserContext } from '../../App';

export default function InfoGeraisTopo() {
    const [exibirModalReceita, setExibirModalReceita] = useState(false)
    const [exibirModalDespesa, setExibirModalDespesa] = useState(false)
    const [exibirModalTransferencia, setExibirModalTransferencia] = useState(false)

    const user = useContext(UserContext)

    return (
        <>
            <div className={styles.info_gerais}>
                <div className={`w-100 ${styles.info_gerais_main}`}>
                    <div className={styles.info_gerais_topo}>

                        <div>
                            <h4>Olá, {user.name}</h4>
                        </div>

                        <div className={styles.info_saldo}>
                            <div>Saldo</div>
                            <div>R$ {user.wallet}</div>
                        </div>
                    </div>

                    <div className={styles.info_gerais_resumo}>
                        <div className={styles.info}>Receitas</div>
                        <div >Despesas</div>
                        <div >Relatórios</div>
                    </div>
                </div>

                <div className={styles.info_gerais_atalhos}>
                    <div className={styles.info_gerias_atalho} onClick={() => setExibirModalReceita(true)} >
                        <FontAwesomeIcon icon={faPlus} />
                        Receita
                    </div>

                    <div className={styles.info_gerias_atalho} onClick={() => setExibirModalDespesa(true)} >
                        <FontAwesomeIcon icon={faMinus} />
                        Despesas
                    </div>

                    <div className={styles.info_gerias_atalho} onClick={() => setExibirModalTransferencia(true)}>
                        <FontAwesomeIcon icon={faArrowsLeftRight} />
                        Transf.
                    </div>

                    <div className={styles.info_gerias_atalho}>
                        <FontAwesomeIcon icon={faUpload} />
                        Importar
                    </div>
                </div>
            </div>

            <ModalReceitas dataModal={[]} exibirModal={exibirModalReceita} setExibirModal={setExibirModalReceita} />
            <ModalDespesas dataModal={[]} exibirModal={exibirModalDespesa} setExibirModal={setExibirModalDespesa} />
            <ModalTransferencia dataModal={[]} exibirModal={exibirModalTransferencia} setExibirModal={setExibirModalTransferencia} />
        </>
    );
}
