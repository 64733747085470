import React, { useState } from "react";
import ModalDespesas from "../../Modal/ModalDespesas";
import Card from "../Card";

export default function GastosMes() {
    const [exibirModalDespesa, setExibirModalDespesa] = useState(false);

    return (
        <>
            <Card title="Gastos do Mês">
                <div className="flex flex-col items-center gap-3">
                    <h3>comece adicionando despesas</h3>
                    <p>
                        Adicione despesas ou configure sua categoria para começar a
                        ver as despesas
                    </p>
                    <button
                        className="btn btn-success"
                        onClick={() => setExibirModalDespesa(true)}
                    >
                        Adicionar Despesas
                    </button>
                </div>
            </Card>

            <ModalDespesas
                exibirModal={exibirModalDespesa}
                setExibirModal={setExibirModalDespesa}
            />
        </>
    );
}
