import React from "react";
import InfoGeraisDashboard from "../components/InfoGeraisDashboard";
import InfoGeraisTopo from "../components/InfoGeraisTopo";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

export default function Index() {
    useDocumentTitle("Página Inicial");

    return (
        <>
            <InfoGeraisTopo />
            <InfoGeraisDashboard />
        </>
    );
}
