import FinancasAPI from "../FinancasAPI";

export default {
    async all(data = {}) {
        return await FinancasAPI.api.get("/movement", {
            params: data
        });
    },

    async futureRecipe() {
        return FinancasAPI.lancamento.all({
            type: 1,
            future: true
        });
    },

    async futureExpense() {
        return FinancasAPI.lancamento.all({
            type: 2,
            future: true
        });
    },

    async create(data) {
        return await FinancasAPI.api.post("/movement", data);
    },

    async delete(id){
        return await FinancasAPI.api.delete(`/movement/${id}`)
    }
}
