import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import styles from "./styles.module.scss";

export default function GearsConfig() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);;
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <FontAwesomeIcon icon={faScrewdriverWrench} onClick={handleClick} style={{color: "#fff", cursor: 'pointer'}} />

            <Menu className={styles.gears}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Link to="/categoria"><MenuItem onClick={handleClose}>Categoria</MenuItem></Link>
                <Link to="/"><MenuItem onClick={handleClose}>Preferências</MenuItem></Link>
                <Link to="/"><MenuItem onClick={handleClose}>Atividades</MenuItem></Link>
                <Link to="/"><MenuItem onClick={handleClose}>Plano</MenuItem></Link>
            </Menu>
        </>
    );
}