import React from 'react'
import CustomRangeDate from '../../components/CustomRangeDate';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

export default function Relatorio() {
    useDocumentTitle("Relatorios");

    return (
        <div className="flex flex-col bg-white p-5 gap-5">
            <div className="flex justify-between">
                <h2>Relatorio</h2>

                <CustomRangeDate />
            </div>

            <ul className="flex items-center gap-3">
                <li><h3>Receita X Despesas</h3></li>
                <li>Categoria</li>
                <li>Contas</li>
                <li>Tags</li>
            </ul>

            <div className="flex flex-col items-center">
                <h1>Este meio esta vázio!!!</h1>
            </div>
        </div>
    );
}
