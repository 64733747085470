import FinancasAPI from "../FinancasAPI";

export default {
    async all() {
        return await FinancasAPI.api.get("/category");
    },

    async create(name) {
        return await FinancasAPI.api.post("/category", {
            name
        });
    },

    async update(id, name) {
        return await FinancasAPI.api.post(`/category/${id}`,{
            name: name
        })
    },

    async delete(id) {
        return await FinancasAPI.api.delete(`/category/${id}`)
    }
}