import FinancasAPI from "../FinancasAPI";

export default {
    async all() {
        return await FinancasAPI.api.get("/card");
    },

    async create(data) {
        return await FinancasAPI.api.post("/card", data);
    },

    async update(id, data) {
        return await FinancasAPI.api.post(`/card/${id}`, data)
    },

    async delete(id) {
        return await FinancasAPI.api.delete(`/card/${id}`)
    }
}
