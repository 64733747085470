import React from "react";
import CustomRangeDate from "../../components/CustomRangeDate";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export default function LimitesDeGastos() {
    useDocumentTitle("Limite de Gastos");

    return (
        <div className="flex flex-col bg-white p-5 gap-5">
            <div className="flex justify-between">
                <h2>Limites de Gastos</h2>

                <CustomRangeDate />

                <div></div>
            </div>

            <div className="flex flex-col gap-5 items-center">
                <h2>Nenhum Limite de gastos definido para Dezembro</h2>

                <div className="flex gap-3">
                    <button className="btn btn-bold btn-success">
                        Copiar o último mês
                    </button>

                    <button className="btn btn-bold btn-gray">
                        Definir limites de gastos
                    </button>
                </div>
            </div>
        </div>
    );
}
