import React, { useState } from "react";
import styles from "./styles.module.scss";
import FinancasAPI from '../../services/FinancasAPI';
import { useQuery } from "react-query";
import ListaCategoria from "../../components/ListaCategoria";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import ModalCategoria from "../../components/Modal/ModalCategoria";

export default function Categoria() {
    useDocumentTitle("Categorias");

    const { data, isLoading } = useQuery("categoria", FinancasAPI.categoria.all);
    const [aba, setAba] = useState("Despesas");
    const [exibirModalCategoria, SetExibirModalCategoria] = useState(false)

    return (
        <>
            <ModalCategoria exibirModal={exibirModalCategoria } setExibirModal={SetExibirModalCategoria} />

            <div className={styles.dashboard}>
                <div className={styles.container}>
                    <h1>Categoria</h1>

                    <div>
                        <button
                            className="btn_more btn-success"
                            onClick={() =>SetExibirModalCategoria(true)}
                        >
                            + categoria de dispesas
                        </button>
                    </div>
                </div>

                <div className={styles.cabecalho}>
                    <div
                        className={`${styles.filtro} cursor-pointer ${(aba == 'Despesas' && styles.active)}`}
                        onClick={() => setAba('Despesas')}
                    >
                        Despesas
                    </div>

                    <div
                        className={`${styles.filtro} cursor-pointer ${(aba == 'Receitas' &&  styles.active)}`}
                        onClick={() => setAba('Receitas')}
                    >
                        Receitas
                    </div>
                </div>

                <div className={`${styles.receitas_despesas}`}>
                    <div className={styles.estilizar_costa_receitas}>
                        <div className={`${styles.editar_categorias} ${(aba == 'Receitas' ? '' : 'hidden')}`}>
                            <div className={styles.editar_categorias}>
                                {!isLoading ? data.data.d.map(categoria => {
                                    if (categoria.type == 2) {
                                        return (
                                            <ListaCategoria key={categoria.id} categoria={categoria} />
                                        )
                                    }
                                }) : null}
                            </div>
                        </div>
                    </div>

                    <div className={styles.estilizar_costa_despesas}>
                        <div className={`${styles.editar_categorias} ${(aba == 'Despesas' ? '' : 'hidden')}`}>
                            <div className={styles.editar_categorias}>
                                {!isLoading ? data.data.d.map(categoria => {
                                    if (categoria.type == 1) {
                                        return (
                                            <ListaCategoria key={categoria.id} categoria={categoria} />
                                        )
                                    }
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
