import Footer from "../components/Footer";
import Header from "../components/Header";

export default function LayoutDefault({component: Component}) {
    return (
        <>
            <Header />

            <main className="container">
                <Component/>
            </main>

            <Footer />
        </>
    );
}
