import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import Modal from "../Modal";
import FinancasAPI from '../../../services/FinancasAPI';

export default function ModalReceitas({ exibirModal, setExibirModal, dataModal, }){
    const { data, isLoading } = useQuery("categoria", FinancasAPI.categoria.all);
    const { register, handleSubmit, reset } = useForm();

    useEffect(() => {
        reset({
            name: "",
            amount: "",
            received_at: "",
            account_id: "",
            category_id: "",
        })

        if (Object.keys(dataModal).length > 0) {
            reset(dataModal)
        }
      }, [dataModal])

    const salvar = (data) => {
        data.type = 1;
        const result = dataModal.id ?
            FinancasAPI.cartao.update(dataModal.id, data):
            FinancasAPI.cartao.create(data);


        result.then(() => {
            setExibirModal(false);
            window.location.reload(false);
        });
    }

    return (
        <>
        <Modal exibirModal={exibirModal}>
            <h2>Nova Receita</h2>

            <form className="flex flex-col gap-5" onSubmit={handleSubmit(salvar)}>
                <div className="w-100">
                    <h4>Receita</h4>

                    <TextField
                        label="Receita"
                        variant="outlined"
                        {...register("name")}
                        required
                    />
                </div>

                <div className="flex gap-2">
                    <h4>Valor</h4>

                    <TextField
                        label="Valor"
                        variant="outlined"
                        {...register("amount")}
                        required
                    />
                </div>

                <div className="flex gap-2">
                    <h4>Data</h4>

                    <TextField
                        label="Data"
                        variant="outlined"
                        {...register("received_at")}
                        required
                    />
                </div>

                <div className="flex gap-2">
                    <h4>Contas</h4>

                    <FormControl fullWidth>
                        <InputLabel>Conta</InputLabel>

                        <Select
                            label="Conta"
                            variant="outlined"
                            {...register("account_id")}
                        >
                        </Select>
                    </FormControl>
                </div>

                <div className="flex gap-2">
                    <h4>Categoria</h4>

                    <FormControl fullWidth>
                        <InputLabel>Categoria</InputLabel>

                        <Select
                            label="Categoria"
                            variant="outlined"
                            {...register("category_id")}
                        >
                            {!isLoading ? data.data.d.map(categoria => {
                                if (categoria.type == 2) {
                                    return (
                                        <MenuItem key={categoria.id} value={categoria.id}>{categoria.name}</MenuItem>
                                    )
                                }
                            }) : null}
                        </Select>
                    </FormControl>
                </div>

                <div className="flex justify-center gap-3">
                    <button
                        type="button"
                        className="btn btn-gray"
                        onClick={() => setExibirModal(false)}
                    >
                        Cancelar
                    </button>

                    <button type="submit" className="btn btn-success">Cadastrar</button>
                </div>
            </form>
        </Modal>
        </>
    );
}
