import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import Modal from "../Modal";
import FinancasAPI from '../../../services/FinancasAPI';

export default function ModalDespesas({ exibirModal, setExibirModal }) {
    const { data, isLoading } = useQuery("categoria", FinancasAPI.categoria.all);
    const { register, handleSubmit } = useForm();

    const salvar = (data) => {
        data.type = 2;

        FinancasAPI.lancamento.create(data).then(() => {
            setExibirModal(false);
            window.location.reload(false);
        });
    }

    return (
        <Modal exibirModal={exibirModal}>
            <h3>Nova Despesas</h3>

            <form className="flex flex-col gap-5" onSubmit={handleSubmit(salvar)}>
                <div className="w-100">
                    <h4>Despesas</h4>

                    <TextField
                        label="Despesas"
                        variant="outlined"
                        {...register("name")}
                        required

                    />
                </div>

                <div className="flex gap-2">
                    <h4>Valor</h4>

                    <TextField
                        label="Valor"
                        variant="outlined"
                        {...register("amount")}
                        required
                    />
                </div>

                <div className="flex gap-2">
                    <h4>Data</h4>

                    <TextField
                        label="Data"
                        variant="outlined"
                        {...register("received_at")}
                        required
                    />
                </div>

                <div className="flex gap-2">
                    <h4>Contas | Cartões</h4>

                    <TextField
                        label="Contas | Cartões"
                        variant="outlined"

                    />
                </div>

                <div className="flex gap-2">
                    <h4>Categoria</h4>

                    <FormControl fullWidth>
                        <InputLabel>Categoria</InputLabel>

                        <Select
                            label="Categoria"
                            variant="outlined"
                            {...register("category_id")}
                        >
                            {!isLoading ? data.data.d.map(categoria => {
                                if (categoria.type == 1) {
                                    return (
                                        <MenuItem key={categoria.id} value={categoria.id}>{categoria.name}</MenuItem>
                                    )
                                }
                            }) : null}
                        </Select>
                    </FormControl>
                </div>

                <div className="flex justify-center gap-3">
                    <button
                        type="button"
                        className="btn btn-gray"
                        onClick={() => setExibirModal(false)}
                    >
                        <h4>Cancelar</h4>
                    </button>

                    <button type="submit" className="btn btn-success">Cadastrar</button>
                </div>
            </form>
        </Modal>
    );
}
