import React from "react";
import Modal from "../Modal";
import styles from "./styles.module.scss";

export default function ModalTransferencia({ exibirModal, setExibirModal }) {
    return (
        <Modal exibirModal={exibirModal}>
            <h3>Transferência</h3>

            <div className={styles.modal_corpo}>
                <div className={styles.aviso}>
                    <h4>Você precisa cadastrar no minímo duas contas</h4>
                    <h4>para fazer Transferência</h4>
                </div>

                <div className={styles.botao}>
                    <button className="btn btn-success">Cadastrar</button>
                </div>

                <div className={styles.botao}>
                    <button
                        className="btn btn-gray"
                        onClick={() => setExibirModal(false)}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );
}
