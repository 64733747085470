import React, { useState } from 'react';
import { convertDateToLocaleDate } from '../../helpers/date';
import FinancasAPI from '../../services/FinancasAPI';
import ModalReceitas from '../Modal/ModalReceita';
import ModalDespesas from '../Modal/ModalDespesas';


export default function CardLancamento({ lancamentos }) {
    const [exibirModalReceita, setExibirModalReceita] = useState(false);
    const [exibirModalDespesas, setExibirModalDespesas] = useState(false);
    const [dataLancamentos, setDataLancamentos] = useState([])

    const colorDate = (dateString) => {
        // Colocar aqui.. se ja estiver em atraso, mostrar a data em vermelho,
        // Se tiver no dia, mostrar em amarelo?
        // se não, mostrar cinza
        return "text-color-gray400"
    }

    const lancamentoImagem = (lancamentoTipo) => {
        return (lancamentoTipo == 1) ?
            "/assets/img/financas.png" :
            "/assets/img/contaspagar.png";
    }



    const editar = (lancamento) => {
        setDataLancamentos(lancamento)

        if (lancamento.type == 1) {
            setExibirModalReceita(true)
        } else {
            setExibirModalDespesas(true)
        }
    }

    const deletar = async (id) => {
        await FinancasAPI.lancamento.delete(id);
        window.location.reload(false);
    }

    return (
        <>
            <div className="flex flex-col gap-3">
                {lancamentos.map((lancamento, index) => {
                    return (
                        <>
                            <div className="flex gap-5 justify-between" key={lancamento.id}>
                                <div className="flex gap-5">
                                    <img src={lancamentoImagem(lancamento.type)} width={50} height={50} />

                                    <div>
                                        <h4>{lancamento.name}</h4>
                                        <small>R$ {lancamento.amount}</small>
                                    </div>
                                </div>

                                <div className="flex flex-col text-right">
                                    <small className={`font-bold ${colorDate(lancamento.received_at)}`}>{convertDateToLocaleDate(lancamento.received_at)}</small>
                                    <div className='cursor-pointer text-color-success'
                                        onClick={() => editar(lancamento)}

                                    >
                                        Editar </div>


                                    <div className="flex flex-col text-right">
                                        <div className='cursor-pointer text-color-danger'
                                            onClick={() => deletar(lancamento.id)}
                                        >
                                            Excluir </div>

                                    </div>


                                </div>
                            </div>

                            {((lancamentos.length - 1) != index) && <hr />}
                        </>
                    );
                })}
            </div>

            <ModalReceitas dataModal={dataLancamentos}
                exibirModal={exibirModalReceita}
                setExibirModal={setExibirModalReceita}
            />

            <ModalDespesas dataModal={dataLancamentos}
                exibirModal={exibirModalDespesas}
                setExibirModal={setExibirModalDespesas}
            />

        </>
    )
}


