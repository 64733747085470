import React from "react";
import styles from "./styles.module.scss";
import { TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FinancasAPI from "../../services/FinancasAPI";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export default function Register() {
    useDocumentTitle("Criar Conta");

    const navigate = useNavigate();

    const { register, handleSubmit } = useForm();


    const salvar = (data) => {
        FinancasAPI.user.register(data).then((data) => {
            localStorage.setItem("userToken", data.data.d.authorization);
            navigate("/");
        });
    };

    return (
        <div className={styles.dashboard}>
            <div className={styles.logo}>
                <img src="/assets/img/logo.png" />
            </div>

            <div className={styles.header}>Criar sua conta</div>

            <div className={styles.bordas}>
                <div className={styles.creater}>
                    <div className={styles.edit_box}>
                        <TextField
                            label="Nome Completo"
                            variant="outlined"
                            {...register("name")}
                            required
                        />
                    </div>

                    <div>
                        <TextField
                            label="E-mail"
                            variant="outlined"
                            {...register("email")}
                            required
                        />
                    </div>

                    <div>
                        <TextField
                            type="password"
                            label="Criar Senha"
                            variant="outlined"
                            {...register("password")}
                            required
                        />
                    </div>

                    <div>
                        <TextField
                            type="password"
                            label="Confirmar Senha"
                            variant="outlined"
                            {...register("confirmPassword")}
                            required
                        />
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="btn btn-success"
                            onClick={handleSubmit(salvar)}
                        >
                            Criar Conta
                        </button>
                    </div>

                    <div>
                        <Link to="/login">
                            <button type="submit" className="btn btn_default">
                                Voltar
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
