import React from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import GearsConfig from './GearsConfig';
import HeaderAvatar from './HeaderAvatar';

export default function Header() {
    return (
        <header className={styles.header} >
            <div className={`container ${styles.hedear_container}`}>
                <div className={styles.header_logo}>
                    <Link to="/"><img src="/assets/img/logo.png" /></Link>
                </div>

                <ul className={styles.hedear_menu}>
                    <li><Link to="/">Visão Geral</Link></li>
                    <li><Link to="/lancamentos">Lançamentos</Link></li>
                    <li><Link to="/relatorio">Relatorios</Link></li>
                    <li><Link to="/limitesdegastos">Limites de Gastos</Link></li>
                </ul>

                <ul className={styles.menu_superior}>
                    <GearsConfig/>
                    <HeaderAvatar/>
                </ul>
            </div>
        </header>
    );
}
